import React from 'react';
import './Services.css';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

function Services(props) {
    return(
        <div className="main-content">
            <Card className="services-card">
                <img src="images/services.png" className="services-header" alt="Braun Massage Therapy"/>
                <Card.Header>
                    <h1>Services</h1>
                </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <b>Inquire about on-site events: Table and/or chair available.</b>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <h3>Swedish Massage</h3>
                        <p className="services-p">
                            Great for de-stressing after a long day at the office or with the kids.<br />
                            Your choice of calming or invigorating lotion or oil.
                        </p>
                        <p className="services-p">
                            <b><u>Table Massage</u></b>
                            <p className="services-p">
                                <b>30 Minutes:</b> $35
                            </p>
                            <p className="services-p">
                                <b>60 Minutes:</b> $55
                            </p>
                        </p>
                        <p className="services-p">
                            <b><u>Chair Massage</u></b>
                            <p className="services-p">
                                <b>30 Minutes:</b> $1 / minute
                            </p>
                            <p className="services-p">
                                <b>60 Minutes:</b> Inquire about chair specials
                            </p>
                        </p>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <h3>Deep Tissue Massage</h3>
                        <p className="services-p">
                            Relaxes tired, sore muscles to to overuse, computer, driving discomfort, or sports tension.
                        </p>
                        <p className="services-p">
                            <b><u>Table Massage</u></b>
                            <p className="services-p">
                                <b>30 Minutes:</b> $45
                            </p>
                            <p className="services-p">
                                <b>60 Minutes:</b> $65
                            </p>
                        </p>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <h3>Foot Bath & Massage</h3>
                        <p className="services-p">
                            Rejuvenating foot bath for tired feet (15 min)<br />
                            & foot massage - the ultimate treat (15 min)
                        </p>
                        <p className="services-p">
                            <b>$25</b>
                        </p>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <h3>Face, Scalp & Neck Massage</h3>
                        <p className="services-p">
                            30 minutes
                        </p>
                        <p className="services-p">
                            <b>$25</b>
                        </p>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <h3>Hand & Foot Massage</h3>
                        <p className="services-p">
                            30 minutes
                        </p>
                        <p className="services-p">
                            <b>$25</b>
                        </p>
                    </ListGroup.Item>
                </ListGroup>
            </Card>
        </div>
    )
}

export default Services;