import React from 'react';
import './Contact.css';

function Contact(props) {
    return(
        <div className="main-content">
            <h1 style={{marginTop: 150}}>This page coming soon.</h1>
        </div>
    )
}

export default Contact;