import React from 'react';

function NotFound(props){
    return(
        <div style={{textAlign: "center", marginTop: 150}}>
            <h1>Sorry, that page could not be found.</h1>
        </div>
    )
}

export default NotFound;