import React from 'react';
import './About.css';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

function About(props) {
    return(
        <div className="main-content">
            <Card className="about-card">
                <img src="images/profile.jpg" className="about-header" alt="Braun Massage Therapy"/>
                <Card.Header>
                    <b>About Me</b>
                </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <b>Education & Professional Licensure</b>
                        <p className="about-p">
                            I graduated with honors as a Certified Massage Therapist in 2004.<br />
                            I am National Board Certiified in Therapeutic Massage (NCTM). I am licensed in both Illinois and Missouri.
                            I specialize in relaxation therapy, back, shoulder, and neck pain, migraine relief, and deep tissue massage.
                            I have specialized training in rotator cuff issues.
                        </p>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <b>Mission Statement</b>
                        <p className="about-p">
                            I believe as a successful Licensed Massage Therapist, that I will assist each client in working towards their goal of improving their overall health.
                            I will accomplish this by carefully listening to the client's concerns and formulating an individualized plan of care, with follow-up treatment.
                            Therapy will be affordable and accessible to all.
                        </p>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <b>Vision Statement</b>
                        <p className="about-p">
                            God is the ultimate healer, the Great Physician.
                            It is with His guidance that  my goal is to deliver professional therapeutic massage in a competent, caring manner to each individual.
                            Clients will receive education on how to reduce stress and manage pain.
                            Based on result-oriented service, clients will experience pain and stress relief, which motivates them to refer family, friends, and co-workers to this practice.
                            Gift certificates allow clients to give a gift that is thoughtful, unique and provides the recipient with a feeling of being pampered.


                        </p>
                    </ListGroup.Item>
                </ListGroup>
            </Card>
        </div>
    )
}

export default About;
