import NavBar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './PageHeader.css';
import {Link} from 'react-router-dom';
import React from 'react';

function PageHeader(props){
    return(
        <NavBar bg="dark" variant="dark" className="page-header">
            <NavBar.Brand href="/">Braun Massage Therapy</NavBar.Brand>
            <Nav>
                <Nav.Link>
					<Link to="/" className="header-link">
                    	Home
					</Link>
                </Nav.Link>
                <Nav.Link>
                    <Link to="/about" className="header-link">
						About
					</Link>
                </Nav.Link>
                <Nav.Link>
					<Link to="/services" className="header-link">
						Services
					</Link>
                </Nav.Link>
                <Nav.Link>
					<Link to="/contact" className="header-link">
						Contact
					</Link>
                </Nav.Link>
            </Nav>
        </NavBar>
    )
}

export default PageHeader