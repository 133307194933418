import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter, Route, Switch} from "react-router-dom";
import createBrowserHistory from "history/createBrowserHistory";
import Home from './components/Home/Home';
import About from './components/About/About';
import Services from './components/Services/Services';
import PageHeader from './components/PageHeader/PageHeader';
import Contact from './components/Contact/Contact';
import NotFound from './components/NotFound';
import './index.css';

const history = createBrowserHistory();

ReactDOM.render(
    <HashRouter history={history} hashType="slash">
            <div className="background">
                <PageHeader/>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path='/about' component={About} />
                    <Route path='/services' component={Services} />
                    <Route path='/contact' component={Contact} />
                    // <Route component={NotFound} />
                </Switch>
            </div>
    </HashRouter>
, document.getElementById('root'));
