import React from 'react';
import './Home.css';
import Card from 'react-bootstrap/Card';

function Home(props){
    return(
        <div className="main-content">
            <Card className="card">
                <div className="header-container">
                    <img src="images/massage.jpg" width="100%" height="50%" alt="Braun Massage Therapy"/>
                    <div className="header-text">Braun Massage Therapy</div>
                </div>
                <p className="home-text">
                    Thank you for visiting Braun Massage Therapy.<br /><br />
                    My name is Nancy Braun, and I am a National Board Certified Massage Therapist.<br />
                    I am licensed in Illinois and Missouri to provide therapeutic massage to patients/clients.<br /><br />
                    Please feel free to learn more about the <a href="services">services</a> I provide, and <a href="contact">contact me</a> if you would like to schedule an appointment.
                </p>
            </Card>
        </div>
    )
}

export default Home;